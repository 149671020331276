import React, { Component } from "react";
import classNames from "classnames";

import BackgroundImage from "../BackgroundImage";
import PlayIcon from "../img/Play";

class YoutubePlayer extends Component {
  constructor() {
    super();

    this.state = { isReady: false, youtubePlayerState: -1 };
    this.playVideo = this.playVideo.bind(this);
    this.pauseVideo = this.pauseVideo.bind(this);
    this.initYoutubeApi = this.initYoutubeApi.bind(this);
    this.onPlayerReady = this.onPlayerReady.bind(this);
    this.onPlayerStateChange = this.onPlayerStateChange.bind(this);
    this.onPlayerStart = this.onPlayerStart.bind(this);
    this.onPlayerStop = this.onPlayerStop.bind(this);
  }

  componentDidMount() {
    this.initYoutubeApi();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const oembedChanged = this.props.oembed !== nextProps.oembed;
    const isPlayerActiveChanged =
      this.props.isPlayerActive !== nextProps.isPlayerActive;
    const isReadyChanged = this.state.isReady !== nextState.isReady;

    const shouldUpdate =
      oembedChanged || isPlayerActiveChanged || isReadyChanged;

    return shouldUpdate;
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.isPlayerActive === true &&
      nextProps.isPlayerActive === false
    ) {
      this.pauseVideo();
    }
  }

  enableYoutubeJsApi(embed) {
    return embed.replace(
      'feature=oembed"',
      'enablejsapi=1&showinfo=0&modestbranding=0&color=red&autohide=1&controls=1" id="video"'
    );
  }

  initYoutubeApi() {
    // Inject YouTube API script
    if (window && window.YT) {
      this.registerPlayer();
    } else {
      if (document) {
        let tag = document.createElement("script");
        tag.src = "//www.youtube.com/player_api";
        let firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      }

      if (window) {
        window.onYouTubePlayerAPIReady = () => {
          // create the global player from the specific iframe (#video)
          this.registerPlayer();
        };
      }
    }
  }

  registerPlayer() {
    this.player = new window.YT.Player("video", {
      events: {
        // call this function when player is ready to use
        onReady: this.onPlayerReady,
        onStateChange: this.onPlayerStateChange
      }
    });
  }

  onPlayerReady() {
    console.log("player ready");
    this.setState({ isReady: true });
  }

  onPlayerStateChange(event) {
    /*
        Youtube API:
       -1 (unstarted)
        0 (ended)
        1 (playing)
        2 (paused)
        3 (buffering)
        5 (video cued)
    */
    this.setState({ youtubePlayerState: event.data });

    // const isPlaying = !(event.data === -1 || event.data === 0 || event.data === 2);
    if (this.isPlaying(event.data)) {
      this.onPlayerStart();
    } else {
      this.onPlayerStop();
    }

    if (this.isEnded(event.data)) {
      this.playVideo();
    }
  }

  isPlaying(eventId) {
    return !(eventId === -1 || eventId === 0 || eventId === 2);
  }

  isEnded(eventId) {
    return eventId === 0;
  }

  playVideo() {
    this.youtubePlayerRef.focus();
    this.player && this.player.playVideo();
    console.log("PLAYING VIDEO");
  }

  pauseVideo() {
    this.player && this.player.pauseVideo();
    console.log("PAUSING VIDEO");
  }

  onPlayerStart() {
    this.props.onPlayerStart();
    console.log("ON PLAYER START");
  }

  onPlayerStop() {
    this.props.onPlayerStop();
    console.log("ON PLAYER STOP");
  }

  render() {
    const { oembed, featured, isPlayerActive, coverImage } = this.props;
    const { isReady } = this.state;

    const embedString = this.enableYoutubeJsApi(oembed);

    const playerClass = classNames("youtube-player", {
      "youtube-player--featured": featured
    });
    const videoClass = classNames("youtube-player__video", {
      "youtube-player__video--featured": featured,
      "youtube-player__video--active": isPlayerActive
    });
    const overlayClass = classNames("youtube-player__overlay", {
      "youtube-player__overlay--active": isPlayerActive
    });
    const coverImageClass = classNames("youtube-player__cover-image", {
      "youtube-player__cover-image--active": isPlayerActive,
      "youtube-player__cover-image--no-cover": !coverImage
    });

    const linkClickHandler = !isReady ? () => {} : this.playVideo;

    const showCoverImage =
      this.state.youtubePlayerState === -1 ||
      this.state.youtubePlayerState === 0 ||
      this.state.youtubePlayerState === 2;

    return (
      <div
        className={playerClass}
        ref={el => (this.youtubePlayerRef = el)}
        onBlur={this.pauseVideo}
      >
        {showCoverImage && (
          <BackgroundImage
            thumbnailUrl={coverImage && coverImage.sizes.thumbnail}
            imageUrl={coverImage ? coverImage.url : ""}
            alt={coverImage ? coverImage.alt : "placeholder-image"}
            className={coverImageClass}
          >
            <div
              className="youtube-player-standalone__link"
              onClick={linkClickHandler}
            >
              {isReady && (
                <div className="youtube-player__play-icon">
                  <PlayIcon />
                </div>
              )}
            </div>
          </BackgroundImage>
        )}
        <div className={overlayClass} />
        <div className={videoClass}>
          <div dangerouslySetInnerHTML={{ __html: embedString }} />
        </div>
      </div>
    );
  }
}

export default YoutubePlayer;
