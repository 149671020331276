import React from "react";

import BackgroundImage from "./BackgroundImage";

const Client = ({ clientLogo, clientTitle }) => (
  <div className="clients__client">
    <img
      className="clients__client-logo"
      src={clientLogo.url}
      alt={`${clientTitle} logo`}
    />
  </div>
);

const ClientGroup = ({ groupTitle, groupImage, clients }) => (
  <BackgroundImage className="clients__group" image={groupImage}>
    <h2 className="clients__group-title">{groupTitle}</h2>
    <div className="clients__group-gallery">
      {clients && clients.map(client => <Client {...client} />)}
    </div>
  </BackgroundImage>
);

const ClientGroups = ({ clientGroups }) => (
  <div className="clients">
    {clientGroups.map(group => (
      <ClientGroup {...group} />
    ))}
  </div>
);

export default ClientGroups;
