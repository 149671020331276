import React, { Component } from 'react';

export default class extends Component {
  shouldComponentUpdate (nextProps) {
    return this.props.fetching !== nextProps.fetching;
  }
  render() {
    if (!this.props.fetching) {
      return null;
    }
    return (
      <div className="sbg-spinner">
        <div /><div /><div />
      </div>
    );
  }
}
