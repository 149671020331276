import React, { Component } from 'react';

import {
  CAROUSEL_INTERVAL_TIME_MS,
  CAROUSEL_TRANSITION_TIME_MS,
  CAROUSEL_SLIDE_EASING,
  CAROUSEL_FADE_EASING
} from '../../constants';

import Fader from './fade-carousel';
import Slider from './slide-carousel';

class Carousel extends Component {
  render () {
    const {
      className,
      rtl,
      children,
      carouselId,
      addGlobalCarousel,
      initialSlide,
      vertical,
      autoplay,
      fade
    } = this.props;

    const opts = {
      initialSlide,
      time: CAROUSEL_TRANSITION_TIME_MS,
      easing: fade ? CAROUSEL_FADE_EASING : CAROUSEL_SLIDE_EASING,
      reverseDirection: rtl,
      vertical,
      autoplay,
      interval: CAROUSEL_INTERVAL_TIME_MS,
      className,
      meetHalfway: true
    };

    const CarouselInner = fade ? Fader : Slider;

    return (
      <CarouselInner
        {...opts}
        ref={node => {
          if (node) {
            const carouselInstance = {
              nextSlide: node.slideNext,
              prevSlide: node.slidePrev,
              goToSlide: node.slideTo
            };

            addGlobalCarousel(carouselId, carouselInstance);
          }
        }}
      >
        { children }
      </CarouselInner>
    );
  }
}

export default Carousel;
