import React, { Component } from "react";

import Collage from "../components/Collage";
import withLayout from "./withLayout";

class Home extends Component {
  render() {
    const { carousel: slides, oembed, videoCoverImage } = this.props.data;

    return (
      <Collage
        slides={slides}
        video={{ oembed, featured: false, coverImage: videoCoverImage }}
        hasRollover={true}
        pageName="home"
      />
    );
  }
}

export default withLayout(Home);
