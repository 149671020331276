import React, { Component } from "react";

import LocationMap from "../LocationMap";
import ContactForm from "../ContactForm";

const Address = ({ title, address, tel }) => (
  <section className="mobile-contact__address">
    <h2 className="mobile-contact__address-title">{title}</h2>
    {address && (
      <div
        dangerouslySetInnerHTML={{ __html: address }}
        className="mobile-contact__address-text"
      />
    )}
    {tel && (
      <div className="mobile-contact__tel">
        <a href={`tel:${tel}`}>{tel}</a>
      </div>
    )}
  </section>
);

class MobileContact extends Component {
  render() {
    const {
      mapsLocation,
      addressTitle,
      address,
      telephoneNumber,
      addressTitleSecondary,
      addressSecondary,
      telephoneNumberSecondary,
      contactFormTitle,
      googleApiKey
    } = this.props;

    return (
      <div className="mobile-contact">
        <header className="mobile-contact__header">
          <h1 className="mobile-contact__title">Contact</h1>
        </header>
        <Address title={addressTitle} address={address} tel={telephoneNumber} />
        {addressTitleSecondary && (
          <Address
            title={addressTitleSecondary}
            address={addressSecondary}
            tel={telephoneNumberSecondary}
          />
        )}
        <section className="mobile-contact__map">
          {mapsLocation && (
            <LocationMap location={mapsLocation} apiKey={googleApiKey} />
          )}
        </section>
        <section className="mobile-contact__contact-form">
          <h2 className="mobile-contact__contact-form-title">
            {contactFormTitle}
          </h2>
          <ContactForm />
        </section>
      </div>
    );
  }
}

export default MobileContact;
