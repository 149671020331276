import React from "react";

import { Link } from "react-router-dom";

import classNames from "classnames";

// const getSlug = url => {
//   const parts = url.split("/");
//   return parts.length > 2 ? parts[parts.length - 2] : "";
// };

const linkClass = (parentClass, { activeItem, slug }) => {
  return classNames(`${parentClass}__link`, {
    [`${parentClass}__link--orange`]: true,
    [`${parentClass}__link--active`]: activeItem === slug
  });
};

const SubMenu = ({ parentClass, items, activeItem, disabled }) => {
  return (
    <div className="header__sub-menu">
      {items.map(item => {
        return (
          <Link
            to={item.linkTo}
            key={`sub-menu-item-${item.ID || item.id}`}
            data-link-text={item.title}
            className={linkClass(parentClass, { activeItem, slug: "" })}
            tabIndex={disabled && -1}
          >
            {item.title}
          </Link>
        );
      })}
    </div>
  );
};

export default SubMenu;
