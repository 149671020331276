import React, { Component } from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect
} from "react-router-dom";

import { apiBase } from "./config";

import ErrorBoundary from "./components/ErrorBoundary";
import Logo from "./components/Logo";

import Home from "./templates/Home";
import Tiles from "./templates/Tiles";
import LongForm from "./templates/LongForm";
import Blog from "./templates/Blog";
import About from "./templates/About";
import Contact from "./templates/Contact";
import BlogSingle from "./templates/BlogSingle";
import Error404 from "./templates/Error404";

import { toHash, buildMenu, toBlog } from "./helpers/parse-data";

const get = (parse = x => x) => url =>
  fetch(`${apiBase}/${url}`)
    .then(res => {
      if (!res.ok) {
        throw res;
      }

      return res;
    })
    .then(res => res.json())
    .then(parse);

class App extends Component {
  state = {
    home: null,
    productsPage: null,
    products: null,
    loaded: false,
    failed: false
  };

  async componentDidMount() {
    await this.fetchSiteData();
    this.removeSiteLoader();
  }

  async fetchSiteData() {
    const apiMapping = {
      homePage: {
        url: "wp-json/acf/v3/pages?slug[]=home-page",
        parse: res => res[0].acf
      },
      productsPage: {
        url: "wp-json/acf/v3/pages?slug[]=products",
        parse: res => res[0].acf.tiles
      },
      solutionsPage: {
        url: "wp-json/acf/v3/pages?slug[]=solutions",
        parse: res => res[0].acf.tiles
      },
      products: {
        url: "wp-json/wp/v2/product",
        parse: toHash
      },
      productsMenu: {
        url: "wp-json/menus/v1/menus/product-menu",
        parse: res => buildMenu(res.items)
      },
      solutionsMenu: {
        url: "wp-json/menus/v1/menus/application-menu",
        parse: res => buildMenu(res.items)
      },
      solutions: {
        url: "wp-json/wp/v2/solution",
        parse: toHash
      },
      news: {
        url: "wp-json/wp/v2/news?per_page=100",
        parse: toBlog
      },
      caseStudies: {
        url: "wp-json/wp/v2/casestudies?per_page=100",
        parse: toBlog
      },
      aboutPage: {
        url: "wp-json/acf/v3/pages?slug[]=about",
        parse: res => res[0].acf
      },
      contactPage: {
        url: "wp-json/acf/v3/pages?slug[]=contact",
        parse: res => res[0].acf
      },
      legal: {
        url: "wp-json/wp/v2/legal"
      },
      settings: {
        url: "wp-json/acf/v3/options/sbg-settings",
        parse: res => res.acf
      }
    };
    const keys = Object.keys(apiMapping);

    try {
      const res = await Promise.all(
        keys.map(key => get(apiMapping[key].parse)(apiMapping[key].url))
      );
      const apiRes = res.reduce((accum, r, i) => {
        accum[keys[i]] = r;

        return accum;
      }, {});

      /* Only update state if content has changed */
      const contentLength = JSON.stringify(apiRes).length;

      if (contentLength !== this.state.contentLength) {
        this.setState(apiRes);
        this.setState({ contentLength });
        this.setState({ loaded: true });
      }
    } catch (e) {
      this.setState({ failed: true });
      console.error(e);
    }
  }

  removeSiteLoader = () => {
    const siteLoaderEl = document.querySelector("#site-loader");
    const bodyEl = document.querySelector("body");

    if (!this.state.siteLoaderRemoved) {
      this.setState({ siteLoaderRemoved: true });
      setTimeout(() => siteLoaderEl.classList.add("site-loader--loaded"), 200);
      setTimeout(() => bodyEl.removeChild(siteLoaderEl), 450);
    }
  };

  render() {
    const {
      homePage,
      products,
      productsPage,
      solutions,
      loaded,
      failed,
      solutionsPage,
      news,
      aboutPage,
      contactPage,
      caseStudies
    } = this.state;

    if (failed) {
      return (
        <div className="api-failure">
          <div className="api-failure__logo">
            <Logo />
          </div>
          <div className="api-failure__message">Resource Not Found</div>
        </div>
      );
    }

    if (!loaded) {
      return <div>LOADING</div>;
    }

    return (
      <ErrorBoundary>
        <Router>
          <div className="app">
            <Switch>
              <Route
                exact
                path="/"
                component={() => <Home {...this.state} data={homePage} />}
              />
              <Route
                exact
                path="/products"
                component={() => <Tiles {...this.state} tiles={productsPage} />}
              />
              <Route
                exact
                path="/product/:articleTitle/:sectionId?"
                component={props => (
                  <LongForm {...this.state} data={products} {...props} />
                )}
              />
              <Route
                exact
                path="/solutions"
                component={() => (
                  <Tiles {...this.state} tiles={solutionsPage} />
                )}
              />
              <Route
                exact
                path="/solution/:articleTitle/:sectionId?"
                component={props => (
                  <LongForm {...this.state} data={solutions} {...props} />
                )}
              />
              <Route
                exact
                path="/about"
                component={props => (
                  <About {...this.state} data={aboutPage} {...props} />
                )}
              />
              <Route
                exact
                path="/contact"
                component={props => (
                  <Contact {...this.state} data={contactPage} {...props} />
                )}
              />
              <Route
                exact
                path="/news"
                component={props => (
                  <Blog
                    {...this.state}
                    data={news}
                    postType="news"
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/news/:slug"
                component={props => {
                  const newsItem = news.find(
                    el => el.slug === props.match.params.slug
                  );
                  if (!newsItem) {
                    return <Error404 {...this.state} />;
                  }
                  return (
                    <BlogSingle
                      {...this.state}
                      {...newsItem}
                      postType="news"
                      {...props}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/casestudies"
                component={props => (
                  <Blog
                    {...this.state}
                    data={caseStudies}
                    postType="casestudies"
                    options={{ noDates: true }}
                    {...props}
                  />
                )}
              />
              <Route
                exact
                path="/casestudies/:slug"
                component={props => {
                  const newsItem = caseStudies.find(
                    el => el.slug === props.match.params.slug
                  );
                  if (!newsItem) {
                    return <Error404 {...this.state} />;
                  }
                  return (
                    <BlogSingle
                      {...this.state}
                      {...newsItem}
                      postType="casestudies"
                      options={{ noDates: true }}
                      {...props}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/legal/:slug"
                component={props => {
                  return <Redirect to={`/#${props.match.params.slug}`} />;
                }}
              />
              <Route
                exact
                path="/mpa"
                component={() => <Redirect to="/#master-purchase-agreement" />}
              />
              <Route component={() => <Error404 {...this.state} />} />
            </Switch>
          </div>
        </Router>
      </ErrorBoundary>
    );
  }
}

export default App;
