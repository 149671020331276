import React, { Component } from "react";
import classNames from "classnames";
import MediaQuery from "react-responsive";

import CarouselNav from "./carousel/CarouselNav";
import MixedCarousel from "./carousel/MixedCarousel";
import YoutubePlayer from "./video/YoutubePlayer";

import { getOffset } from "./carousel/MixedCarousel";

import {
  CAROUSEL_INTERVAL_TIME_MS,
  CAROUSEL_TRANSITION_TIME_MS
} from "../constants";

class Collage extends Component {
  static defaultProps = {
    autoplay: true
  };

  constructor(props) {
    super(props);

    this.carousels = {};

    this.state = {
      current: 0,
      total: 0,
      transitioning: false,
      isPlayerActive: false,
      activeHover: null,
      isPaused: !props.autoplay
    };

    this.removeGlobalCarousel = this.removeGlobalCarousel.bind(this);
    this.addGlobalCarousel = this.addGlobalCarousel.bind(this);
    this.carouselEach = this.carouselEach.bind(this);
    this.goNext = this.goNext.bind(this);
    this.goPrev = this.goPrev.bind(this);
    this.clickNext = this.clickNext.bind(this);
    this.clickPrev = this.clickPrev.bind(this);
    this.updateTotal = this.updateTotal.bind(this);
    this.startCarousel = this.startCarousel.bind(this);
    this.stopCarousel = this.stopCarousel.bind(this);
    this.pauseCarousel = this.pauseCarousel.bind(this);
    this.unpauseCarousel = this.unpauseCarousel.bind(this);
    this.onPlayerStart = this.onPlayerStart.bind(this);
    this.onPlayerStop = this.onPlayerStop.bind(this);
    this.setActiveHover = this.setActiveHover.bind(this);
    this.goTo = this.goTo.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { slides } = nextProps;

    this.updateTotal(slides.length);
  }

  componentDidMount() {
    const { slides } = this.props;

    this.updateTotal(slides.length);
    this.startCarousel();
  }

  componentWillUnmount() {
    this.stopCarousel();
  }

  setActiveHover(pos) {
    this.setState({ activeHover: pos });
  }

  updateTotal(newTotal) {
    if (this.state.total !== newTotal) {
      this.setState({ total: newTotal });
    }
  }

  addGlobalCarousel(id, sw) {
    if (!this.carousels[id]) {
      this.carousels[id] = sw;
    }
  }

  removeGlobalCarousel(id) {
    delete this.carousels[id];
  }

  goNext() {
    if (!this.state.transitioning) {
      this.carouselEach(carousel => carousel.nextSlide());
      const { current, total } = this.state;
      const next = current === total - 1 ? 0 : current + 1;
      this.setState({ current: next, transitioning: true });
      window.setTimeout(
        () => this.setState({ transitioning: false }),
        CAROUSEL_TRANSITION_TIME_MS
      );
    }
  }

  goPrev() {
    if (!this.state.transitioning) {
      this.carouselEach(carousel => carousel.prevSlide());
      const { current, total } = this.state;
      const prev = current === 0 ? total - 1 : current - 1;
      this.setState({ current: prev, transitioning: true });
      window.setTimeout(
        () => this.setState({ transitioning: false }),
        CAROUSEL_TRANSITION_TIME_MS
      );
    }
  }

  goTo(i) {
    if (!this.state.transitioning) {
      this.carouselEach((carousel, key) => {
        const j = typeof key === "number" ? key : parseInt(key[0], 10);
        const offset = getOffset(j, this.state.total);
        const slideIndex = (i + offset) % this.state.total;
        carousel.goToSlide(slideIndex);
      });
      this.setState({ current: i, transitioning: true });
      window.setTimeout(
        () => this.setState({ transitioning: false }),
        CAROUSEL_TRANSITION_TIME_MS
      );
    }
  }

  clickNext() {
    this.stopCarousel();
    this.goNext();
    this.startCarousel();
  }

  clickPrev() {
    this.stopCarousel();
    this.goPrev();
    this.startCarousel();
  }

  carouselEach(fn) {
    Object.keys(this.carousels).forEach(key => fn(this.carousels[key], key));
  }

  startCarousel() {
    if (
      !this.state.isPaused &&
      this.props.autoplay &&
      this.props.slides.length > 1
    ) {
      this.stopCarousel();
      this.timer = setTimeout(() => {
        this.goNext();
        this.startCarousel();
      }, CAROUSEL_INTERVAL_TIME_MS);
    }
  }

  stopCarousel() {
    clearTimeout(this.timer);
  }

  pauseCarousel() {
    clearTimeout(this.timer);
    this.setState({ isPaused: true });
  }

  unpauseCarousel() {
    this.goNext();
    this.setState({ isPaused: false }, this.startCarousel);
  }

  onPlayerStart() {
    this.setState({ isPlayerActive: true });
    this.stopCarousel();
  }

  onPlayerStop() {
    this.setState({ isPlayerActive: false });
    this.startCarousel();
  }

  render() {
    const {
      slides,
      hasRollover,
      video,
      pageName,
      loading,
      pageTitle,
      pageIcon,
      internalLinks,
      autoplay
    } = this.props;

    const { current, total, activeHover } = this.state;

    const isMobile = window.innerWidth < 1024;

    const collageClass = classNames("collage", { "collage--loading": loading });

    return (
      <div className={collageClass} onClick={this.onPlayerStop}>
        {slides[1] && (
          <CarouselNav
            goPrev={this.clickPrev}
            goNext={this.clickNext}
            current={current}
            total={total}
            pauseable={autoplay}
            play={this.unpauseCarousel}
            pause={this.pauseCarousel}
            isPaused={this.state.isPaused}
          />
        )}
        {slides[0] && (
          <MixedCarousel
            position={0}
            color="black"
            current={current}
            startCarousel={this.startCarousel}
            stopCarousel={this.stopCarousel}
            removeGlobalCarousel={this.removeGlobalCarousel}
            addGlobalCarousel={this.addGlobalCarousel}
            imageArray={slides.map(slide => slide.image)}
            contentArray={slides}
            hasRollover={hasRollover && !isMobile}
            pageName={pageName}
            internalLinks={internalLinks}
            pageTitle={pageTitle}
            pageIcon={pageIcon}
            isHovered={activeHover === 0 || isMobile}
            setActiveHover={this.setActiveHover}
          />
        )}
        {slides[1] && (
          <MixedCarousel
            position={1}
            color="green"
            current={current}
            startCarousel={this.startCarousel}
            stopCarousel={this.stopCarousel}
            removeGlobalCarousel={this.removeGlobalCarousel}
            addGlobalCarousel={this.addGlobalCarousel}
            imageArray={slides.map(slide => slide.image)}
            contentArray={slides}
            hasRollover={hasRollover && !isMobile}
            pageName={pageName}
            internalLinks={internalLinks}
            isHovered={activeHover === 1 && !isMobile}
            setActiveHover={this.setActiveHover}
            rtl={true}
            goToSlide={this.goTo}
          />
        )}
        {video && video.oembed && (
          <section className="mixed-carousel mixed-carousel--vid">
            <YoutubePlayer
              oembed={video.oembed}
              coverImage={video.coverImage}
              featured={video.featured}
              onPlayerStop={this.onPlayerStop}
              onPlayerStart={this.onPlayerStart}
              isPlayerActive={this.state.isPlayerActive}
              className="mixed-carousel__carousel"
            />
          </section>
        )}
        {slides[2] && (
          <MixedCarousel
            position={2}
            color="orange"
            current={current}
            startCarousel={this.startCarousel}
            stopCarousel={this.stopCarousel}
            removeGlobalCarousel={this.removeGlobalCarousel}
            addGlobalCarousel={this.addGlobalCarousel}
            imageArray={slides.map(slide => slide.image)}
            contentArray={slides}
            hasRollover={hasRollover && !isMobile}
            pageName={pageName}
            internalLinks={internalLinks}
            isHovered={activeHover === 2 && !isMobile}
            setActiveHover={this.setActiveHover}
            vertical={true}
            goToSlide={this.goTo}
          />
        )}
        {slides[3] && (
          <MediaQuery minWidth={1024}>
            <MixedCarousel
              position={3}
              color="red"
              current={current}
              startCarousel={this.startCarousel}
              stopCarousel={this.stopCarousel}
              removeGlobalCarousel={this.removeGlobalCarousel}
              addGlobalCarousel={this.addGlobalCarousel}
              imageArray={slides.map(slide => slide.image)}
              contentArray={slides}
              hasRollover={hasRollover && !isMobile}
              pageName={pageName}
              internalLinks={internalLinks}
              isHovered={activeHover === 3 && !isMobile}
              setActiveHover={this.setActiveHover}
              vertical={true}
              rtl={true}
              goToSlide={this.goTo}
            />
          </MediaQuery>
        )}
      </div>
    );
  }
}

export default Collage;
