import React, { Component } from "react";
import classNames from "classnames";

import Spinner from "./Spinner";

const validations = {
  first_name: {
    test: value => !!value,
    message: "Please tell us your First Name"
  },
  last_name: {
    test: value => !!value,
    message: "Please tell us your Last Name"
  },
  email: {
    // eslint-disable-next-line
    test: value =>
      /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      ),
    message: "Looks like that's not a valid email address!"
  },
  description: {
    test: value => !!value,
    message: "The Message field can't be left empty."
  }
};

class ContactForm extends Component {
  constructor(props) {
    super();

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.state = {
      fetching: false,
      success: false,
      failed: false,
      invalidFields: {},
      values: {
        // debug: "1",
        // debugEmail: "",
        oid: "00D24000000po6L",
        retUrl: "https://sbgsportssoftware.com",
        description: props.placeholderText || ""
      }
    };
  }

  componentDidUpdate(prevProps) {
    const { placeholderText } = this.props;
    if (placeholderText !== prevProps.placeholderText) {
      this.setState({
        values: {
          ...this.state.values,
          description: placeholderText || ""
        }
      });
    }
  }

  onSubmit(e) {
    this.setState({ fetching: true, failed: false, success: false });

    const { values } = this.state;

    const invalidFields = Object.keys(validations).reduce(
      (accum, fieldName) => {
        const value = values[fieldName];
        const validation = validations[fieldName];

        if (validation && !validation.test(value)) {
          accum[fieldName] = validation.message;
        }

        return accum;
      },
      {}
    );

    const isValid = Object.keys(invalidFields).length === 0;

    if (!isValid) {
      e.preventDefault();
      this.setState({
        invalidFields,
        failed: true,
        fetching: false,
        message: invalidFields[Object.keys(invalidFields)[0]]
      });
    } else {
      setTimeout(
        () =>
          this.setState({
            success: true,
            failed: false,
            fetching: false,
            message:
              "Thank you for getting in touch, we'll get back to you shortly."
          }),
        300
      );
    }
  }

  parseInvalidFields(invalidFields) {
    if (Array.isArray(invalidFields)) {
      return invalidFields.reduce((accum, field) => {
        const parts = field.into.split(".");
        const fieldName = parts[parts.length - 1];

        accum[fieldName] = field.message;

        return accum;
      }, {});
    }
  }

  onChange(e) {
    const { name, value } = e.target;

    this.setState({
      invalidFields: { ...this.state.invalidFields, [name]: "" },
      values: { ...this.state.values, [name]: value }
    });
  }

  render() {
    const {
      invalidFields,
      values,
      message,
      fetching,
      success,
      failed
    } = this.state;

    const submitClass = classNames("contact-form__submit", {
      "contact-form__submit--success": success,
      "contact-form__submit--failed": failed
    });

    const messageClass = classNames("contact-form__res-message", {
      "contact-form__res-message--success": success,
      "contact-form__res-message--failed": failed
    });

    return (
      <div className="contact-form">
        <iframe
          title="salesforce"
          id="wtl"
          name="wtl"
          src="about:blank"
          style={{ display: "none" }}
        />
        {success ? null : (
          <form
            onSubmit={this.onSubmit}
            target="wtl"
            action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
            method="POST"
          >
            <input type="hidden" name="oid" value="00D24000000po6L" />
            <input
              type="hidden"
              name="retURL"
              value="https://sbgsportssoftware.com"
            />
            {/* <input type="hidden" name="debug" value={1} />
            <input
              type="hidden"
              name="debugEmail"
              value="mathsgregory@gmail.com"
            /> */}
            <Input
              name="first_name"
              placeholder="First Name *"
              onChange={this.onChange}
              values={values}
              invalidFields={invalidFields}
            />
            <Input
              name="last_name"
              placeholder="Last Name *"
              onChange={this.onChange}
              values={values}
              invalidFields={invalidFields}
            />
            <Input
              name="company"
              placeholder="Company"
              onChange={this.onChange}
              values={values}
              invalidFields={invalidFields}
            />
            <Input
              name="email"
              placeholder="Email Address *"
              onChange={this.onChange}
              values={values}
              invalidFields={invalidFields}
            />
            <Input
              name="phone"
              placeholder="Phone Number"
              onChange={this.onChange}
              values={values}
              invalidFields={invalidFields}
            />
            <textarea
              name="description"
              placeholder="Message *"
              value={values.description}
              onChange={this.onChange}
              className={classNames(
                "contact-form__input contact-form__textarea",
                {
                  "contact-form__input--invalid": invalidFields.description
                }
              )}
            />
            <button className={submitClass} disabled={fetching}>
              Submit
            </button>
            {fetching && (
              <div className="contact-form__spinner">
                <Spinner />
              </div>
            )}
            {failed && message && <div className={messageClass}>{message}</div>}
            <div />
          </form>
        )}
        {success && message && <div className={messageClass}>{message}</div>}
      </div>
    );
  }
}

const Input = ({ values, invalidFields, onChange, name, placeholder }) => {
  const inputClass = classNames("contact-form__input", {
    "contact-form__input--invalid": invalidFields[name]
  });

  return (
    <input
      key={`contact-form-input-${name}`}
      className={inputClass}
      value={values[name]}
      name={name}
      placeholder={placeholder}
      onChange={onChange}
    />
  );
};

export default ContactForm;
