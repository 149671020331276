import React, { Component } from "react";
import { Link } from "react-router-dom";
import format from "date-fns/format";
import MediaQuery from "react-responsive";
import withLayout from "./withLayout";

import MobileArticle from "../components/mobile/MobileArticle";

import ArrowLeft from "../components/img/ArrowLeft";
import BackgroundImage from "../components/BackgroundImage";
import ScrollBar from "../components/ScrollBar";

class BlogSingle extends Component {
  componentDidMount() {
    this.updateContentHeight();
    window.addEventListener("resize", this.updateContentHeight);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateContentHeight);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.title !== this.props.title) {
      window.scrollTop = 0;
      this.updateContentHeight();
    }
  }

  updateContentHeight() {
    if (this.contentEl) {
      const headerHeight = this.headerEl.scrollHeight;
      this.contentEl.style.height = `calc(100% - ${headerHeight}px)`;
    }
  }

  render() {
    const { image, date, title, content, postType, options } = this.props;

    return (
      <div>
        <MediaQuery maxWidth={1023}>
          <MobileArticle {...this.props} />
        </MediaQuery>
        <MediaQuery minWidth={1024}>
          <div className="article text--dark">
            <BackgroundImage
              className="article__cover-image"
              imageUrl={image.url}
              thumbnailUrl={image.sizes.thumbnail}
              filter={true}
            />
            <div className="article__article">
              <header
                className="article__header"
                ref={el => (this.headerEl = el)}
              >
                <div className="article__back-btn">
                  <Link className="article__back-btn-link" to={`/${postType}`}>
                    <ArrowLeft />
                    Back
                  </Link>
                </div>
                {options.noDates || (
                  <div className="article__date p--sm">
                    {format(date, "D MMMM YYYY")}
                  </div>
                )}
                <div className="article__title">
                  <h1 dangerouslySetInnerHTML={{ __html: title }} />
                </div>
              </header>
              <article
                className="article__content p--sm"
                ref={el => (this.contentEl = el)}
              >
                <ScrollBar withPadding={true}>
                  <div dangerouslySetInnerHTML={{ __html: content }} />
                </ScrollBar>
              </article>
            </div>
          </div>
        </MediaQuery>
      </div>
    );
  }
}

export default withLayout(BlogSingle, {
  footer: { shadow: true },
  header: { shadow: true }
});
