import React from "react";
import MediaQuery from "react-responsive";

import ArrowLeft from "../img/ArrowLeft";
import ArrowRight from "../img/ArrowRight";
import Pause from "../img/Pause";
import Play from "../img/Play";

const addLeadingZero = x => (x < 10 ? "0" + x : x);

const CarouselNav = ({
  goPrev,
  goNext,
  current,
  total,
  pauseable,
  isPaused,
  play,
  pause
}) => (
  <div className="carousel-nav">
    {total && (
      <div className="carousel-nav__pg">
        <span className="carousel-nav__pg-num carousel-nav__pg-num--current">
          {addLeadingZero(current + 1)}
        </span>
        <span>|</span>
        <span className="carousel-nav__pg-num carousel-nav__pg-num--total">
          {addLeadingZero(total)}
        </span>
      </div>
    )}
    <div className="carousel-nav__btns">
      <button
        tabIndex={0}
        className="carousel-nav__btn carousel-nav__prev"
        onClick={goPrev}
      >
        <span tabIndex={-1} className="carousel-nav__btn-content">
          <ArrowLeft />
          <span className="sr-only">Previous slide</span>
        </span>
      </button>
      <MediaQuery minWidth={1024}>
        {pauseable && isPaused ? (
          <button
            tabIndex={0}
            className="carousel-nav__btn carousel-nav__play"
            onClick={play}
          >
            <span tabIndex={-1} className="carousel-nav__btn-content">
              <Play />
              <span className="sr-only">Play carousel</span>
            </span>
          </button>
        ) : null}
      </MediaQuery>
      <MediaQuery minWidth={1024}>
        {pauseable && !isPaused ? (
          <button
            tabIndex={0}
            className="carousel-nav__btn carousel-nav__pause"
            onClick={pause}
          >
            <span tabIndex={-1} className="carousel-nav__btn-content">
              <Pause />
              <span className="sr-only">Pause carousel</span>
            </span>
          </button>
        ) : null}
      </MediaQuery>
      <button
        tabIndex={0}
        className="carousel-nav__btn carousel-nav__next"
        onClick={goNext}
      >
        <span tabIndex={-1} className="carousel-nav__btn-content">
          <ArrowRight />
          <span className="sr-only">Next slide</span>
        </span>
      </button>
    </div>
  </div>
);

export default CarouselNav;
