import React, { Component } from "react";
import classnames from "classnames";

const modulo = (a, n) => ((a % n) + n) % n;

class Slider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      current: props.initialSlide,
      outgoing: 0
    };

    this.slideTo = this.slideTo.bind(this);
    this.slideNext = this.slideNext.bind(this);
    this.slidePrev = this.slidePrev.bind(this);
    this.onSlideEnd = this.onSlideEnd.bind(this);
    this.onSlideStart = this.onSlideStart.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.addSlideClasses = this.addSlideClasses.bind(this);
    this.queueTransition = this.queueTransition.bind(this);
  }

  addSlideClasses(children) {
    const { current, transitioning } = this.state;
    const { time, easing } = this.props;

    return React.Children.map(children, (child, index) => {
      const className = classnames(
        child.props.className,
        "react-fade-carousel__slide",
        {
          "react-fade-carousel__slide--current": index === current,
          "react-fade-carousel__slide--transitioning": transitioning
        }
      );
      const style = { transition: `opacity ${time}ms ${easing}` };

      return React.cloneElement(child, {
        key: `react-fade-carousel__slide--${index}`,
        className,
        style
      });
    });
  }

  componentDidMount() {
    if (this.props.keyboardNav) {
      window.addEventListener("keydown", this.onKeyPress);
    }
    if (this.props.autoplay) {
      this.autoplayStart(this.props.interval);
    }
  }

  componentWillUnmount() {
    if (this.props.keyboardNav) {
      window.removeEventListener("keydown", this.onKeyPress);
    }
    this.autoplayStop();
  }

  autoplayStart(interval) {
    this.autoplayTimeout = setTimeout(() => {
      this.slideNext();
      this.autoplayStart(interval);
    }, interval);
  }

  autoplayStop() {
    clearTimeout(this.autoplayTimeout);
  }

  onKeyPress({ keyCode }) {
    if (this.state.transitioning) {
      return;
    }
    if (keyCode === 37) {
      return this.slidePrev();
    }
    if (keyCode === 39) {
      return this.slideNext();
    }
  }

  queueTransition(newIndex) {
    this.onSlideStart();
    if (this.props.meetHalfway && typeof newIndex === "number") {
      setTimeout(
        () => this.setState({ current: newIndex }),
        this.props.time / 2
      );
    }
    setTimeout(this.onSlideEnd, this.props.time);
  }

  slidePrev() {
    const i = modulo(this.state.current - 1, this.props.children.length);
    this.slideTo(i);
  }

  slideNext() {
    const i = modulo(this.state.current + 1, this.props.children.length);
    this.slideTo(i);
  }

  slideTo(i) {
    if (this.state.transitioning) return;

    setTimeout(
      () => this.setState({ current: this.props.meetHalfway ? undefined : i }),
      0
    );

    return this.queueTransition(i);
  }

  onSlideEnd() {
    return this.setState({ transitioning: false });
  }

  onSlideStart() {
    return this.setState({ transitioning: true });
  }

  reverseSlidesIfNecessary(slides) {
    if (!this.props.reverseDirection) {
      return slides;
    }
    return slides.concat([]).reverse();
  }

  processChildren(children) {
    return this.addSlideClasses(children);
  }

  render() {
    const { children, className } = this.props;

    const sliderClass = classnames("react-fade-carousel", {
      [className]: !!className
    });

    return (
      <div className={sliderClass}>
        <div
          ref={el => {
            if (el) {
              this.wrapperRef = el;
              this.slides = Array.from(el.children);
            }
          }}
          className="react-fade-carousel__wrapper"
        >
          {this.processChildren(children)}
        </div>
      </div>
    );
  }
}

Slider.defaultProps = {
  initialSlide: 0,
  time: 300,
  interval: 1800,
  easing: "ease-in",
  reverseDirection: false,
  keyboardNav: false,
  meetHalfway: false
};

export default Slider;
