import React from "react";

const About = ({ title, text }) => {
  return (
    <div className="about text--dark">
      <h1 className="about__title">{title}</h1>
      <div className="about__text" dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export default About;
