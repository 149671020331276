import React, { Component } from "react";

class FullHeight extends Component {
  constructor() {
    super();
    this.state = { innerHeight: "100vh" };
  }

  onUpdate = () => {
    if (this.state.innerHeight !== window.innerHeight) {
      console.log("state change");
      this.setState({
        innerHeight: window.innerHeight
      });
    }
  };

  componentDidMount() {
    console.log("mounted");
    this.onUpdate();
    window.addEventListener("resize", this.onUpdate, true);
    window.addEventListener("scroll", this.onUpdate, true);
  }

  componentWillUnmount() {
    console.log("unmounting");
    window.removeEventListener("resize", this.onUpdate, true);
    window.removeEventListener("scroll", this.onUpdate, true);
  }

  render() {
    console.log("render");
    const { Tag = "div", children, ...props } = this.props;
    const { innerHeight } = this.state;

    return (
      <Tag style={{ height: innerHeight }} {...props}>
        {children}
      </Tag>
    );
  }
}

export default FullHeight;
