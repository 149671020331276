import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import ArrowRight from "../img/ArrowRight";

const MenuItem = item => {
  const { level, linkTo, title, children } = item;

  const hasChildren = children.length > 0;

  return (
    <li
      className={classnames("menu-item", `menu-item--level-${level}`, {
        "menu-item--has-children": hasChildren
      })}
    >
      {!linkTo ? (
        <span className="menu-item__title">{title}</span>
      ) : (
        <Link
          className={classnames("link-fill", "menu-item__link")}
          to={linkTo}
        >
          {title}
        </Link>
      )}
      {hasChildren && (
        <span className="menu-item__right-arrow">
          <ArrowRight />
        </span>
      )}
      {hasChildren && <MenuItems items={children} />}
    </li>
  );
};
const MenuItems = ({ items }) => {
  const level = items[0] && items[0].level;

  return (
    <ul className={classnames("menu", `menu--level-${level}`)}>
      {items.map(MenuItem)}
    </ul>
  );
};

class DropdownMenu extends Component {
  render() {
    const { menu, isOpen } = this.props;

    const items = menu.nested;

    return <MenuItems items={items} />;
  }
}

export default DropdownMenu;
