import React, { Component } from "react";
import format from "date-fns/format";

import PostLink from "./PostLink";
import BackgroundImage from "../components/BackgroundImage";
import SearchResult from "../components/SearchResult";
import ScrollBar from "../components/ScrollBar";
import Spinner from "../components/Spinner";

class News extends Component {
  state = {
    isHovered: false
  };

  onInputChange = e => {
    this.props.search(e.target.value);
  };

  onMouseEnter = () => {
    this.setState({ isHovered: true });
  };

  onMouseLeave = () => {
    this.setState({ isHovered: false });
  };

  render() {
    const { coverStory, searchResults, fetching, options } = this.props;

    let coverImage = searchResults[0] && searchResults[0].image;
    let coverDate = searchResults[0] && searchResults[0].date;
    let coverTitle = searchResults[0] && searchResults[0].title;

    /* Comment the below if statement to show top search result as cover */
    if (coverStory) {
      coverImage = coverStory.image;
      coverDate = coverStory.date;
      coverTitle = coverStory.title;
    }

    return (
      <div className="news text--dark">
        <BackgroundImage
          className="news__cover-image"
          imageUrl={coverImage && coverImage.url}
          thumbnailUrl={coverImage && coverImage.sizes.thumbnail}
          color={this.state.isHovered}
        >
          {coverTitle && (
            <div className="news__cover-text">
              {!options.noDates && (
                <div className="news__cover-date p--sm">
                  {coverDate && format(coverDate, "D MMMM YYYY")}
                </div>
              )}
              <PostLink
                {...coverStory}
                className="news__cover-link"
                miscProps={{
                  onMouseEnter: this.onMouseEnter,
                  onFocus: this.onMouseEnter,
                  onMouseLeave: this.onMouseLeave,
                  onBlur: this.onMouseLeave
                }}
              >
                <h1
                  dangerouslySetInnerHTML={{ __html: coverTitle }}
                  className="news__cover-title"
                />
                {this.state.isHovered && (
                  <div
                    className="news__cover-read-more"
                    data-link-text="Read More"
                  >
                    Read More
                  </div>
                )}
              </PostLink>
            </div>
          )}
        </BackgroundImage>
        <div className="news__search">
          <div className="news__search-bar">
            <div className="news__spinner">
              <Spinner fetching={fetching} />
            </div>
            <label className="news__search-label" htmlFor="search">
              <span className="sr-only">Search for news</span>
            </label>
            <input
              name="search"
              placeholder="Search"
              className="news__search-input p--sm"
              onKeyUp={this.onInputChange}
              type="text"
            />
          </div>
          <div className="news__search-results">
            <ScrollBar>
              <div className="news__search-results-wrap">
                {searchResults.map(result => (
                  <SearchResult
                    key={`result-id-${result.id}`}
                    result={result}
                    options={options}
                  />
                ))}
              </div>
            </ScrollBar>
          </div>
        </div>
      </div>
    );
  }
}

export default News;
