import React, { Component } from "react";
import classNames from "classnames";
import MediaQuery from "react-responsive";

import Header from "../components/Header";
import Footer from "../components/Footer";
import Legal from "../components/Legal";

import MobileHeader from "../components/mobile/MobileHeader";
import MobileMenu from "../components/mobile/MobileMenu";

const withLayout = (Template, options = {}) =>
  class extends Component {
    state = {
      menuOpen: false,
      legalOpen: false,
      hashLink: undefined
    };

    constructor() {
      super();

      this.openLegal = this.openLegal.bind(this);
      this.closeLegal = this.closeLegal.bind(this);

      this.closeMenu = this.closeMenu.bind(this);
      this.toggleMenu = this.toggleMenu.bind(this);
    }

    openLegal(hashLink) {
      if (hashLink) {
        this.setState({ hashLink, legalOpen: true });
        this.closeMenu();
      }
    }

    closeLegal() {
      // TODO: Use js routing?
      this.setState({ hashLink: undefined, legalOpen: false });
      window.history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search
      );
    }

    closeMenu() {
      this.setState({ menuOpen: false });
    }

    toggleMenu() {
      this.setState({ menuOpen: !this.state.menuOpen });
    }

    componentWillReceiveProps(nextProps) {
      const thisMatch = this.props.match || {};
      const nextMatch = nextProps.match || {};

      if (thisMatch.url !== nextMatch.url) {
        this.closeLegal();
        this.closeMenu();
      }
    }

    componentDidMount() {
      this.openLegal(window.location.hash.split("#")[1]);

      window.onhashchange = () =>
        this.openLegal(window.location.hash.split("#")[1]);
    }

    render() {
      const {
        headerMenuItem,
        legal,
        productsMenu,
        solutionsMenu,
        noLogo,
        loading,
        forceTransparent,
        settings
      } = this.props;

      const { dark, template, header = {}, footer = {} } = options;

      const { hashLink, menuOpen, legalOpen } = this.state;

      const isDarkTheme = !hashLink && dark;

      const pageClass = classNames("layout", {
        [`layout--${template}`]: !!template,
        "layout--overlay-open": legalOpen,
        "text--light": !isDarkTheme,
        "text--dark": isDarkTheme,
        "page--loading": loading
      });

      return (
        <div className={pageClass}>
          <MediaQuery minWidth={1024}>
            <Header
              menus={{ productsMenu, solutionsMenu }}
              headerMenuItem={headerMenuItem}
              dark={isDarkTheme}
              hasShadow={header.shadow && !legalOpen}
              hasGradient={header.hasGradient}
            />
          </MediaQuery>
          <MediaQuery maxWidth={1023}>
            <div>
              <MobileHeader
                noLogo={noLogo}
                menuOpen={menuOpen}
                toggleMenu={this.toggleMenu}
                legalOpen={legalOpen}
                closeLegal={this.closeLegal}
                isDarkTheme={isDarkTheme}
                forceTransparent={forceTransparent}
              />
              <MobileMenu
                settings={settings}
                menuOpen={menuOpen}
                closeMenu={this.closeMenu}
                productsMenu={productsMenu}
                solutionsMenu={solutionsMenu}
                legal={legal}
              />
            </div>
          </MediaQuery>
          <Template {...this.props} options={this.props.options || {}} />
          <MediaQuery minWidth={1024}>
            <Footer
              {...this.props}
              activeItem={hashLink}
              legal={legal}
              openLegal={this.openLegal}
              hasShadow={footer.shadow && !legalOpen}
              lightText={footer.lightText}
              {...options.footer}
            />
          </MediaQuery>
          <Legal
            hashLink={this.state.hashLink}
            legal={legal}
            closeLegal={this.closeLegal}
            legalOpen={legalOpen}
          />
        </div>
      );
    }
  };

export default withLayout;
