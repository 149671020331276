import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";

import Logo from "./Logo";

import HeaderMenu from "./header/HeaderMenu";

class Header extends Component {
  render() {
    const { hasGradient } = this.props;

    return (
      <header
        className={classnames("header", {
          "header--has-gradient": hasGradient
        })}
      >
        <Link to="/">
          <div className="header__logo">
            <Logo />
          </div>
        </Link>
        <nav className="header__nav">
          <HeaderMenu
            activeItem={this.props.headerMenuItem}
            className="header__menu"
            menus={this.props.menus}
          />
        </nav>
        {this.props.hasShadow && (
          <div className="shadow-wrapper">
            <nav className="header__nav header__nav--shadow">
              <HeaderMenu
                disabled={true}
                activeItem={this.props.headerMenuItem}
                className="header__menu"
                menus={this.props.menus}
              />
            </nav>
          </div>
        )}
      </header>
    );
  }
}

export default Header;
