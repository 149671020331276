// This must be the first line in src/index.js
import "react-app-polyfill/ie11";

import React from "react";
import ReactDOM from "react-dom";

import "./styles";

import * as serviceWorker from "./serviceWorker";
import * as serviceWorker2 from "./serviceWorker2";

import App from "./App";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
serviceWorker2.unregister();
