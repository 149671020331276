import React from "react";
import classnames from "classnames";

import PostLink from "../components/PostLink";
import BackgroundImage from "../components/BackgroundImage";

import withLayout from "./withLayout";

const Tiles = ({ tiles = [] }) => {
  tiles = tiles.slice(0, 8);
  const total = tiles.length;

  return (
    <div className={classnames("tiles", `tiles--total-${total}`)}>
      {tiles.map((tile, i) => {
        const j = i + 1;
        return (
          <div
            key={`${tile.image.url},${j}`}
            className={classnames("tiles__tile", `tiles__tile-${j}`)}
          >
            <div className="tile__detail">
              <h2 className="tile__title">
                <PostLink {...tile.link} title={`${tile.title}`} />
              </h2>
              <div className="tile__detail-extra">
                <div
                  className="tile__text-content"
                  dangerouslySetInnerHTML={{ __html: tile.textContent }}
                />
                <div className="tile__links">
                  {tile.links &&
                    tile.links.map(link => (
                      <PostLink {...link.link} title={link.linkText} />
                    ))}
                </div>
              </div>
            </div>
            <div className="tile__background-image">
              <BackgroundImage
                imageUrl={tile.image.url}
                thumbnailUrl={tile.image.sizes.thumbnail}
                zoomed={false}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default withLayout(Tiles, { header: { hasGradient: true } });
