import React from "react";

import withLayout from "./withLayout";

const Error404 = () => <div className="error404">404, not found.</div>;

export default withLayout(Error404, {
  footer: { lightText: true },
  header: { hasGradient: true }
});
