import React, { Component } from "react";

import classNames from "classnames";

class ScrollBar extends Component {
  constructor() {
    super();

    this.state = {
      thumbTop: "0",
      contentHeight: 0
    };

    this.onScroll = this.onScroll.bind(this);
    this.updateScrollNecessary = this.updateScrollNecessary.bind(this);
  }

  componentDidMount() {
    this.updateScrollNecessary();

    window.addEventListener("resize", this.updateScrollNecessary);
  }

  componentDidUpdate(prevProps, prevState) {
    const contentHeight = this.content.clientHeight;
    if (prevState.contentHeight !== contentHeight) {
      this.setState({ contentHeight });
      this.updateScrollNecessary();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScrollNecessary);
  }

  updateScrollNecessary() {
    const contentHeight = this.content.clientHeight;
    const visibleHeight = this.visibleContent.clientHeight;

    this.setState({ scrollNecessary: contentHeight < visibleHeight });
  }

  onScroll(e) {
    // TODO: could use offsetHeight to include borders and scroll!?
    const contentHeight = this.content.clientHeight;
    const scrollTop = e.target.scrollTop;
    const visibleHeight = e.target.clientHeight;
    const trackHeight = this.track.clientHeight;
    const percentScroll = scrollTop / (contentHeight - visibleHeight);
    const thumbTop = percentScroll * trackHeight;

    this.setState({ thumbTop: thumbTop + "px" });
  }
  render() {
    const { thumbTop } = this.state;
    const { withPadding } = this.props;

    const trackClass = classNames("scroll-bar__track", {
      "scroll-bar__track--hidden": this.state.scrollNecessary
    });

    const scrollBarClass = classNames("scroll-bar", {
      "scroll-bar--with-padding": withPadding
    });

    return (
      <div className={scrollBarClass}>
        <div ref={el => (this.track = el)} className={trackClass}>
          <div className="scroll-bar__line" />
          <div style={{ top: thumbTop }} className="scroll-bar__thumb" />
        </div>
        <div className="scroll-bar__content-wrapper">
          <div
            ref={el => (this.visibleContent = el)}
            onScroll={this.onScroll}
            className="scroll-bar__content"
          >
            <div
              className="scroll-bar__content-inner"
              ref={el => (this.content = el)}
            >
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ScrollBar;
